import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getDeminimis = async (params) => {
  const res = await jwtAxios.get(URL_API.DEMINIMIS.GET_ALL, { params });
  return res;
};

const useGetAllDeminimis = (params) =>
  useQuery([URL_API.DEMINIMIS.GET_ALL, params], () => getDeminimis(params), {
    enabled: !!params?.folder_slug && !!params?.company_slug,
  });

export default useGetAllDeminimis;
