export const URL_API = {
  AUTHENTICATION: {
    LOGIN: "auth/login/",
    REGISTRATION: "auth/registration/",
    REGISTRATION_RESEND: "auth/registration/resend/",
    REGISTRATION_CONFIRM: "auth/registration/confirm/",
    PASSWORD_RESET: "auth/password-reset/",
    PASSWORD_RESET_VALIDATE: "auth/password-reset/validate-token/",
    PASSWORD_RESET_CONFIRM: "auth/password-reset/confirm/",
    PASSWORD_CHANGE: "auth/change-password/",
  },
  // Account
  BEGIN: {
    GET_ALL: "account/begin/",
    GET_ONE: "account/begin/",
    CREATE: "account/begin/",
    UPDATE: "account/begin/",
    DELETE: "account/begin/",
    DELETE_BULK: "account/begin/bulk-delete/",
    DELETE_ALL: "account/begin/all/",
    IMPORT: "account/begin/import/",
    GET_HEADER: "account/begin/headers/",
    SAVE_HEADER: "account/begin/setup-headers/",
  },
  PURCHASE: {
    GET_ALL: "account/purchase/",
    GET_ONE: "account/purchase/",
    CREATE: "account/purchase/",
    UPDATE: "account/purchase/",
    DELETE: "account/purchase/",
    DELETE_BULK: "account/purchase/bulk-delete/",
    DELETE_ALL: "account/purchase/all/",
    IMPORT: "account/purchase/import/",
    GET_HEADER: "account/purchase/headers/",
    SAVE_HEADER: "account/purchase/setup-headers/",
  },

  ACC_IOB: {
    GET_ALL: "account/iob/",
    GET_ONE: "account/iob/",
    CREATE: "account/iob/",
    UPDATE: "account/iob/",
    DELETE: "account/iob/",
    DELETE_BULK: "account/iob/bulk-delete/",
    DELETE_ALL: "account/iob/all/",
    IMPORT: "account/iob/import/",
    GET_HEADER: "account/iob/headers/",
    SAVE_HEADER: "account/iob/setup-headers/",
  },
  ACC_PHYSICAL: {
    GET_ALL: "account/physical-inventory/",
    GET_ONE: "account/physical-inventory/",
    CREATE: "account/physical-inventory/",
    UPDATE: "account/physical-inventory/",
    DELETE: "account/physical-inventory/",
    DELETE_BULK: "account/physical-inventory/bulk-delete/",
    DELETE_ALL: "account/physical-inventory/all/",
    IMPORT: "account/physical-inventory/import/",
    GET_HEADER: "account/physical-inventory/headers/",
    SAVE_HEADER: "account/physical-inventory/setup-headers/",
  },
  ACC_OTHER_OUTPUT: {
    GET_ALL: "account/other-output/",
    GET_ONE: "account/other-output/",
    CREATE: "account/other-output/",
    UPDATE: "account/other-output/",
    DELETE: "account/other-output/",
    DELETE_BULK: "account/other-output/bulk-delete/",
    DELETE_ALL: "account/other-output/all/",
    IMPORT: "account/other-output/import/",
    GET_HEADER: "account/other-output/headers/",
    SAVE_HEADER: "account/other-output/setup-headers/",
  },
  // Exim
  STATEMENT15: {
    GET_ALL: "exim/statement15/",
    GET_ONE: "exim/statement15/",
    CREATE: "exim/statement15/",
    UPDATE: "exim/statement15/",
    DELETE: "exim/statement15/",
    DELETE_BULK: "exim/statement15/bulk-delete/",
    DELETE_ALL: "exim/statement15/all/",
    IMPORT: "exim/statement15/import/",
    GET_HEADER: "exim/statement15/headers/",
    SAVE_HEADER: "exim/statement15/setup-headers/",
  },
  STATEMENT15A: {
    GET_ALL: "exim/statement15A/",
    GET_ONE: "exim/statement15A/",
    CREATE: "exim/statement15A/",
    UPDATE: "exim/statement15A/",
    DELETE: "exim/statement15A/",
    DELETE_BULK: "exim/statement15A/bulk-delete/",
    DELETE_ALL: "exim/statement15A/all/",
    IMPORT: "exim/statement15A/import/",
    GET_HEADER: "exim/statement15A/headers/",
    SAVE_HEADER: "exim/statement15A/setup-headers/",
  },
  ECUS: {
    GET_ALL: "exim/ecus/",
    GET_ONE: "exim/ecus/",
    CREATE: "exim/ecus/",
    UPDATE: "exim/ecus/",
    DELETE: "exim/ecus/",
    DELETE_BULK: "exim/ecus/bulk-delete/",
    DELETE_ALL: "exim/ecus/all/",
    GET_ALL_INVOICE: "exim/ecus/invoice/",
    GET_ONE_INVOICE: "exim/ecus/invoice/",
    IMPORT: "exim/ecus/import/",
    GET_HEADER: "exim/ecus/headers/",
    SAVE_HEADER: "exim/ecus/setup-headers/",
  },
  // Balance
  MAP_CODE: {
    GET_ALL: "balance/map-code/",
    GET_ONE: "balance/map-code/",
    CREATE: "balance/map-code/",
    UPDATE: "balance/map-code/",
    DELETE: "balance/map-code/",
    DELETE_BULK: "balance/map-code/bulk-delete/",
    DELETE_ALL: "balance/map-code/all/",
    IMPORT: "balance/map-code/import/",
    GET_HEADER: "balance/map-code/headers/",
    SAVE_HEADER: "balance/map-code/setup-headers/",
  },
  TAX_RISK: {
    GET_ALL: "balance/tax-risk/",
    GET_ONE: "balance/tax-risk/",
    CREATE: "balance/tax-risk/",
    UPDATE: "balance/tax-risk/",
    DELETE: "balance/tax-risk/",
    DELETE_BULK: "balance/tax-risk/bulk-delete/",
    DELETE_ALL: "balance/tax-risk/all/",
    IMPORT: "balance/tax-risk/import/",
    GET_HEADER: "balance/tax-risk/headers/",
    SAVE_HEADER: "balance/tax-risk/setup-headers/",
  },
  // BOM
  BOM: {
    GET_ALL: "bom/",
    GET_ONE: "bom/",
    CREATE: "bom/",
    UPDATE: "bom/",
    DELETE: "bom/",
    DELETE_BULK: "bom/bulk-delete/",
    DELETE_ALL: "bom/all/",
    IMPORT: "bom/import/",
    GET_HEADER: "bom/headers/",
    SAVE_HEADER: "bom/setup-headers/",
    FIX: "bom/fix/",
  },
  BOM_CLIENT: {
    GET_ALL: "bom/client-bom/",
    GET_ONE: "bom/client-bom/",
    CREATE: "bom/client-bom/",
    UPDATE: "bom/client-bom/",
    DELETE: "bom/client-bom/",
    DELETE_BULK: "bom/client-bom/bulk-delete/",
    DELETE_ALL: "bom/client-bom/all/",
    IMPORT: "bom/client-bom/import/",
    GET_HEADER: "bom/client-bom/headers/",
    SAVE_HEADER: "bom/client-bom/setup-headers/",
    FIX: "bom/client-bom/fix/",
  },
  BOM_TECHNICAL: {
    GET_ALL: "bom/technical-bom/",
    GET_ONE: "bom/technical-bom/",
    CREATE: "bom/technical-bom/",
    UPDATE: "bom/technical-bom/",
    DELETE: "bom/technical-bom/",
    DELETE_BULK: "bom/technical-bom/bulk-delete/",
    DELETE_ALL: "bom/technical-bom/all/",
    IMPORT: "bom/technical-bom/import/",
    GET_HEADER: "bom/technical-bom/headers/",
    SAVE_HEADER: "bom/technical-bom/setup-headers/",
    FIX: "bom/technical-bom/fix/",
  },
  BOM_PURCHASE: {
    GET_ALL: "bom/purchase-bom/",
    GET_ONE: "bom/purchase-bom/",
    CREATE: "bom/purchase-bom/",
    UPDATE: "bom/purchase-bom/",
    DELETE: "bom/purchase-bom/",
    DELETE_BULK: "bom/purchase-bom/bulk-delete/",
    DELETE_ALL: "bom/purchase-bom/all/",
    IMPORT: "bom/purchase-bom/import/",
    GET_HEADER: "bom/purchase-bom/headers/",
    SAVE_HEADER: "bom/purchase-bom/setup-headers/",
    FIX: "bom/purchase-bom/fix/",
  },
  // Annex
  ANNEX: {
    GET_ALL: "fta/annex/",
    GET_ONE: "fta/annex/",
    CREATE: "fta/annex/",
    UPDATE: "fta/annex/",
    DELETE: "fta/annex/",
    DELETE_BULK: "fta/annex/bulk-delete/",
    DELETE_ALL: "fta/annex/all/",
    IMPORT: "fta/annex/import/",
    GET_HEADER: "fta/annex/headers/",
    SAVE_HEADER: "fta/annex/setup-headers/",
  },
  CO_LIST: {
    GET_ALL: "fta/co/",
    GET_ONE: "fta/co/",
    CREATE: "fta/co/",
    UPDATE: "fta/co/",
    DELETE: "fta/co/",
    DELETE_BULK: "fta/co/bulk-delete/",
    DELETE_ALL: "fta/co/all/",
    IMPORT: "fta/co/import/",
    GET_HEADER: "fta/co/headers/",
    SAVE_HEADER: "fta/co/setup-headers/",
  },
  // Master
  BOM_CHANGE: {
    GET_ALL: "master/bom-change/",
    GET_BY_ID: "master/bom-change/",
    UPDATE: "master/bom-change/",
    CREATE: "master/bom-change/",
    DELETE_BULK: "master/bom-change/bulk-delete/",
    DELETE_ALL: "master/bom-change/all/",
    IMPORT: "master/bom-change/import/",
    GET_HEADER: "master/bom-change/headers/",
    SAVE_HEADER: "master/bom-change/setup-headers/",
  },
  COMMODITY: {
    GET_ALL: "master/commodity/",
    GET_BY_ID: "master/commodity/",
    UPDATE: "master/commodity/",
    CREATE: "master/commodity/",
    DELETE_BULK: "master/commodity/bulk-delete/",
    DELETE_ALL: "master/commodity/all/",
    IMPORT: "master/commodity/import/",
    GET_HEADER: "master/commodity/headers/",
    SAVE_HEADER: "master/commodity/setup-headers/",
  },
  FG_DIFF: {
    GET_ALL: "master/fg-diff/",
    GET_BY_ID: "master/fg-diff/",
    UPDATE: "master/fg-diff/",
    CREATE: "master/fg-diff/",
    DELETE_BULK: "master/fg-diff/bulk-delete/",
    DELETE_ALL: "master/fg-diff/all/",
    IMPORT: "master/fg-diff/import/",
    GET_HEADER: "master/fg-diff/headers/",
    SAVE_HEADER: "master/fg-diff/setup-headers/",
  },
  HS_YEAR: {
    GET_ALL: "master/hs-diff-year/",
    GET_BY_ID: "master/hs-diff-year/",
    UPDATE: "master/hs-diff-year/",
    CREATE: "master/hs-diff-year/",
    DELETE_BULK: "master/hs-diff-year/bulk-delete/",
    DELETE_ALL: "master/hs-diff-year/all/",
    IMPORT: "master/hs-diff-year/import/",
    GET_HEADER: "master/hs-diff-year/headers/",
    SAVE_HEADER: "master/hs-diff-year/setup-headers/",
  },
  HS_PARTNER: {
    GET_ALL: "master/hs-diff-partner/",
    GET_BY_ID: "master/hs-diff-partner/",
    UPDATE: "master/hs-diff-partner/",
    CREATE: "master/hs-diff-partner/",
    DELETE_BULK: "master/hs-diff-partner/bulk-delete/",
    DELETE_ALL: "master/hs-diff-partner/all/",
    IMPORT: "master/hs-diff-partner/import/",
    GET_HEADER: "master/hs-diff-partner/headers/",
    SAVE_HEADER: "master/hs-diff-partner/setup-headers/",
  },
  PARTNER: {
    GET_ALL: "master/partner/",
    GET_BY_ID: "master/partner/",
    UPDATE: "master/partner/",
    CREATE: "master/partner/",
    DELETE_BULK: "master/partner/bulk-delete/",
    DELETE_ALL: "master/partner/all/",
    IMPORT: "master/partner/import/",
    GET_HEADER: "master/partner/headers/",
    SAVE_HEADER: "master/partner/setup-headers/",
  },
  PRODUCTION_PROCESS: {
    GET_ALL: "master/production-process/",
    GET_BY_ID: "master/production-process/",
    UPDATE: "master/production-process/",
    CREATE: "master/production-process/",
    DELETE_BULK: "master/production-process/bulk-delete/",
    DELETE_ALL: "master/production-process/all/",
    IMPORT: "master/production-process/import/",
    GET_HEADER: "master/production-process/headers/",
    SAVE_HEADER: "master/production-process/setup-headers/",
  },
  // Other
  CO_FORM: {
    GET_ALL: "other/co-form/",
    DELETE_ALL: "other/co-form/all/",
    IMPORT: "other/co-form/import/",
    GET_HEADER: "other/co-form/headers/",
    SAVE_HEADER: "other/co-form/setup-headers/",
  },
  DESTROY: {
    GET_ALL: "other/destroy/",
    GET_ONE: "other/destroy/",
    CREATE: "other/destroy/",
    UPDATE: "other/destroy/",
    DELETE: "other/destroy/",
    DELETE_BULK: "other/destroy/bulk-delete/",
    DELETE_ALL: "other/destroy/all/",
    IMPORT: "other/destroy/import/",
    GET_HEADER: "other/destroy/headers/",
    SAVE_HEADER: "other/destroy/setup-headers/",
  },
  PSR: {
    GET_ALL: "other/psr/",
    GET_ALL_BY_HS: "other/psr/",
    GET_ONE: "other/psr/",
    CREATE: "other/psr/",
    UPDATE: "other/psr/",
    DELETE: "other/psr/",
    DELETE_BULK: "other/psr/bulk-delete/",
    DELETE_ALL: "other/psr/all/",
    IMPORT: "other/psr/import/",
    GET_HEADER: "other/psr/headers/",
    SAVE_HEADER: "other/psr/setup-headers/",
  },
  SELL: {
    GET_ALL: "other/sell/",
    GET_ONE: "other/sell/",
    CREATE: "other/sell/",
    UPDATE: "other/sell/",
    DELETE: "other/sell/",
    DELETE_BULK: "other/sell/bulk-delete/",
    DELETE_ALL: "other/sell/all/",
    IMPORT: "other/sell/import/",
    GET_HEADER: "other/sell/headers/",
    SAVE_HEADER: "other/sell/setup-headers/",
  },
  // Preset Master
  COUNTRY_CODE: {
    GET_ALL: "preset-master/country-code/",
    GET_ALL_BY_HS: "preset-master/country-code/",
    GET_ONE: "preset-master/country-code/",
    CREATE: "preset-master/country-code/",
    UPDATE: "preset-master/country-code/",
    DELETE: "preset-master/country-code/",
    DELETE_BULK: "preset-master/country-code/bulk-delete/",
    DELETE_ALL: "preset-master/country-code/all/",
    IMPORT: "preset-master/country-code/import/",
    GET_HEADER: "preset-master/country-code/headers/",
    SAVE_HEADER: "preset-master/country-code/setup-headers/",
  },
  DIRECT_RVC: {
    GET_ALL: "preset-master/direct-rvc/",
    CREATE: "preset-master/direct-rvc/",
  },
  DEMINIMIS: {
    GET_ALL: "preset-master/deminimis/",
    CREATE: "preset-master/deminimis/",
    DELETE: "preset-master/deminimis/",
  },
  // Warehouse
  IOB: {
    GET_ALL: "warehouse/iob/",
    GET_ONE: "warehouse/iob/",
    CREATE: "warehouse/iob/",
    UPDATE: "warehouse/iob/",
    DELETE: "warehouse/iob/",
    DELETE_BULK: "warehouse/iob/bulk-delete/",
    DELETE_ALL: "warehouse/iob/all/",
    IMPORT: "warehouse/iob/import/",
    GET_HEADER: "warehouse/iob/headers/",
    SAVE_HEADER: "warehouse/iob/setup-headers/",
  },
  PHYSICAL: {
    GET_ALL: "warehouse/physical-inventory/",
    GET_ONE: "warehouse/physical-inventory/",
    CREATE: "warehouse/physical-inventory/",
    UPDATE: "warehouse/physical-inventory/",
    DELETE: "warehouse/physical-inventory/",
    DELETE_BULK: "warehouse/physical-inventory/bulk-delete/",
    DELETE_ALL: "warehouse/physical-inventory/all/",
    IMPORT: "warehouse/physical-inventory/import/",
    GET_HEADER: "warehouse/physical-inventory/headers/",
    SAVE_HEADER: "warehouse/physical-inventory/setup-headers/",
  },
  // History
  HISTORY: {
    GET_ALL: "history/bangke/",
    DEDUCTION: "history/deduction/",
    ROLLBACK: "history/bangke/roll-back/",
    DELETE_BULK: "history/bangke/bulk-delete/",
    DELETE_ALL: "history/bangke/all/",
  },
  DEDUCTION_HISTORY: {
    GET_ALL: "history/deduction/",
  },
  // Logic bangke
  BANG_KE: {
    SAVE: "history/bangke/",
    DEFAULT: "logic-bangke/default/",
    CUSTOM: "logic-bangke/custom/",
    MANUAL_CUSTOM: "logic-bangke/manual/custom/",
    MANUAL_DEFAULT: "logic-bangke/manual/default/",
    RECALCULATE: "logic-bangke/recalculate/",
    DEMINIMIS: "logic-bangke/default/bangke-deminimis/",
  },
  // Logic statement
  BALANCE: {
    GET_ALL: "history/balance/",
    GET_ONE: "history/balance/",
    CREATE_STATEMENT_15: "logic-statement/15/",
    CREATE_STATEMENT_15_VALIDATE: "logic-statement/15/validate-data/",
    CREATE_CHECKING_STATEMENT_15: "logic-statement/15/checking/",
    CREATE_STATEMENT_15A: "logic-statement/15A/",
    CREATE_STATEMENT_15A_VALIDATE: "logic-statement/15A/validate-data/",
    CREATE_CHECKING_STATEMENT_15A: "logic-statement/15A/checking/",
    CREATE_BALANCE: "logic-statement/balance-statement/",
    DELETE_BULK: "history/balance/bulk-delete/",
  },

  // Info
  PROFILE: {
    GET_USER_PROFILE: "info/profile/",
    PUT_USER_PROFILE: "info/profile",
    GET_USER_PERMISSIONS: "info/users/user-permission/",
  },
  // Company
  COMPANY: {
    GET_USER_COMPANY: "companies/",
    PUT_USER_COMPANY: "companies",
    COMPANY_MANAGEMENT: "company-managements/",
  },

  // Upload
  UPLOAD: {
    DOWNLOAD_FORM: "file/download/",
    MERGE_FILE: "file/merge/",
  },

  // Mapping
  MAP_TABLE: {
    GET_ALL_LIST_TABLE: "map/tables/",
    GET_ALL_LIST_FIELD_TABLE: "map/fields/",
    MAP_TABLE_DATA: "map/first/",
    GET_LIST_ALL_DATA: "map/data/",
    LOGIC_MAPPING_TABLE: "map/simulate/",
    MAP_TABLE_MANUAL: "map/manual/",
    GET_MAP_TABLE: "map/",
    GET_MAP_TABLE_BY_ID: "map-table/",
    DELETE_MAP_TABLE_BY_ID: "map-table/",

    DELETE_LIST_MAP_TABLE_DATA: "map-fields/",
    GET_LIST_MAP_TABLE_DATA: "map-fields/",
  },

  // Purchase DO
  PURCHASE_DO: {
    GET_ALL: "account/purchase_do/",
    GET_ONE: "account/purchase_do/",
    CREATE: "account/purchase_do/",
    UPDATE: "account/purchase_do/",
    DELETE: "account/purchase_do/",
    DELETE_BULK: "account/purchase_do/bulk-delete/",
    DELETE_ALL: "account/purchase_do/all/",
    IMPORT: "account/purchase_do/import/",
    GET_HEADER: "account/purchase_do/headers/",
    SAVE_HEADER: "account/purchase_do/setup-headers/",
  },
};
