import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getListMapTableData = async (params) => {
  const res = await jwtAxios.get(URL_API.MAP_TABLE.GET_LIST_MAP_TABLE_DATA, {
    params,
  });
  return res;
};

const useGetListMapTableData = (params, enabled = false) =>
  useQuery(
    [URL_API.MAP_TABLE.GET_LIST_MAP_TABLE_DATA, params],
    () => getListMapTableData(params),
    {
      enabled: enabled && !!params?.folder_slug && !!params?.company_slug,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
    }
  );

export default useGetListMapTableData;
