import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import clsx from "clsx";
import PropTypes from "prop-types";
import QueueAnim from "rc-queue-anim";
import "./index.style.less";
import { Resizable } from "react-resizable";
import { createContext } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";

const DragIndexContext = createContext({
  active: -1,
  over: -1,
});
const dragActiveStyle = (dragState, id) => {
  const { active, over, direction } = dragState;
  // drag active style
  let style = {};
  if (active && active === id) {
    style = {
      backgroundColor: "gray",
      opacity: 0.5,
    };
  }
  // dragover dashed style
  else if (over && id === over && active !== over) {
    style =
      direction === "right"
        ? {
            borderRight: "1px dashed gray",
          }
        : {
            borderLeft: "1px dashed gray",
          };
  }
  return style;
};

const ResizableDraggableTitle = (props) => {
  const {
    onResize,
    width,
    index,
    isLastColumn,
    children,
    draggable,
    ...restProps
  } = props;
  const [isResizing, setIsResizing] = useState(false);

  const dragState = useContext(DragIndexContext);
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.id,
    disabled: isLastColumn || isResizing || !draggable,
  });

  const style = {
    ...props.style,
    cursor: isLastColumn || !draggable ? "default" : "move",
    ...(isDragging && !isLastColumn && !isResizing && draggable
      ? {
          position: "relative",
          zIndex: 9999,
          userSelect: "none",
        }
      : {}),
    ...dragActiveStyle(dragState, props.id),
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleResizeStart = () => {
    setIsResizing(true);
  };

  const handleResizeStop = () => {
    setIsResizing(false);
  };

  if (!width) {
    return (
      <th {...restProps} ref={setNodeRef} style={style}>
        {!isLastColumn && draggable && (
          <span
            className="drag-handle"
            {...listeners}
            {...attributes}
            onClick={stopPropagation}
          >
            :::
          </span>
        )}
        {children}
      </th>
    );
  }

  return isLastColumn || !draggable ? (
    <th {...restProps} ref={setNodeRef} style={style}>
      {children}
    </th>
  ) : (
    <Resizable
      width={width}
      height={0}
      handle={
        <span className="react-resizable-handle" onClick={stopPropagation} />
      }
      onResize={onResize}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
      draggableOpts={{
        enableUserSelectHack: false,
      }}
    >
      <th
        {...restProps}
        ref={setNodeRef}
        style={style}
        onMouseDown={stopPropagation}
      >
        {!isLastColumn && !isResizing && draggable && (
          <span
            className="drag-handle"
            {...listeners}
            {...attributes}
            onClick={stopPropagation}
          >
            :::
          </span>
        )}
        {children}
      </th>
    </Resizable>
  );
};

const TableBodyCell = (props) => {
  const dragState = useContext(DragIndexContext);
  return (
    <td
      {...props}
      style={{
        ...props.style,
        ...dragActiveStyle(dragState, props.id),
      }}
    />
  );
};
const AppTableContainer = (props) => {
  const {
    columns,
    data,
    hoverColor,
    className,
    rowKey,
    rowClassName,
    columnsState,
    setColumnsState,
    ...rest
  } = props;
  useEffect(() => {
    if (typeof setColumnsState === "function") {
      setColumnsState(() =>
        columns?.map((column, i) => {
          const originalRender = column.render;

          const newRender = (text, record) => {
            let content = originalRender ? originalRender(text, record) : text;

            if (React.isValidElement(content) && content.props.style) {
              content = React.cloneElement(content, {
                style: {
                  ...content.props.style,
                  maxWidth: column.width || "100%",
                },
              });
            } else {
              content = (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: column.width || "100%",
                  }}
                >
                  {content}
                </div>
              );
            }

            return content;
          };

          return {
            ...column,
            key: `${i}`,
            onHeaderCell: () => ({
              id: `${i}`,
              draggable: column.draggable !== false,
            }),
            onCell: () => ({
              id: `${i}`,
            }),
            render: newRender,
          };
        })
      );
    }
  }, [rest.isChange]);

  const [dragIndex, setDragIndex] = useState({
    active: -1,
    over: -1,
  });
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  );
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setColumnsState((prevState) => {
        const activeIndex = prevState.findIndex((i) => i.key === active?.id);
        const overIndex = prevState.findIndex((i) => i.key === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
    setDragIndex({
      active: -1,
      over: -1,
    });
  };
  const onDragOver = ({ active, over }) => {
    const activeIndex = columnsState.findIndex((i) => i.key === active.id);
    const overIndex = columnsState.findIndex((i) => i.key === over?.id);
    setDragIndex({
      active: active.id,
      over: over?.id,
      direction: overIndex > activeIndex ? "right" : "left",
    });
  };

  const handleResize =
    (index) =>
    (_, { size }) => {
      const newColumns = [...columnsState];

      const originalRender = newColumns[index].render;

      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
        render: (text, record) => {
          let content = originalRender ? originalRender(text, record) : text;

          if (React.isValidElement(content) && content.props.style) {
            content = React.cloneElement(content, {
              style: {
                ...content.props.style,
                maxWidth: size.width,
              },
            });
          } else {
            content = (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: size.width,
                }}
              >
                {content}
              </div>
            );
          }

          return content;
        },
      };

      setColumnsState(newColumns);
    };

  const mergeColumns = columnsState?.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
      id: col.key,
      index: index,
      isLastColumn: index === columnsState.length - 1,
      draggable: column.draggable !== false,
    }),
    onCell: (record, rowIndex) => ({
      id: `${index}-${rowIndex}`,
    }),
  }));

  const getRowClassName = (record, index) => {
    let className = index % 2 === 0 ? "table-row-light" : "table-row-dark";
    if (rowClassName) {
      className += ` ${rowClassName(record, index)}`;
    }
    return className;
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      collisionDetection={closestCenter}
    >
      <SortableContext
        items={columnsState?.map((i) => i.key)}
        strategy={horizontalListSortingStrategy}
      >
        <DragIndexContext.Provider value={dragIndex}>
          <QueueAnim
            component={Table}
            type="left"
            rowClassName={getRowClassName}
            className={clsx(
              "table-responsive",
              { hoverColor: hoverColor },
              data?.length > 0 ? "" : "empty",
              className
            )}
            showHeader={data?.length > 0 ? true : false}
            components={{
              header: {
                cell: ResizableDraggableTitle,
              },
              body: {
                cell: TableBodyCell,
              },
            }}
            columns={mergeColumns}
            dataSource={data}
            rowKey={rowKey ? rowKey : "_id"}
            scroll={{ x: "max-content", y: "calc(100vh - 290px)" }}
            sticky
            bordered
            hoverColor
            pagination={false}
            {...rest}
          />
        </DragIndexContext.Provider>
      </SortableContext>
    </DndContext>
  );
};

export default AppTableContainer;

AppTableContainer.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.array,
  className: PropTypes.string,
  pagination: PropTypes.object,
  hoverColor: PropTypes.bool,
};

AppTableContainer.defaultProps = {
  pagination: false,
};
