import { LeftOutlined } from "@ant-design/icons";
import AppTableContainer from "@uni/core/AppTableContainer";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import IntlMessages from "@uni/utility/IntlMessages";
import { Button, Spin } from "antd";
import useGetMapTableById from "hooks/apis/mapTable/useGetMapTableById";
import useGetListMapTableData from "hooks/apis/mapTable/useGetListMapTableData";
import useAppShowHideTableColumn from "hooks/UseAppShowHideTableColumn"; // Thêm import
import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { centerItems } from "pages/system/Settings";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";

const TABLE_KEY = "MappingDetailTable";

const fetchMapTableById = async (mappingPath, params) => {
  try {
    const response = await jwtAxios.get(
      `${URL_API.MAP_TABLE.GET_MAP_TABLE_BY_ID}${mappingPath}`,
      { params }
    );
    return response.results; // Giả sử response.results chứa dữ liệu
  } catch (error) {
    console.error("Error fetching map table:", error);
    throw error;
  }
};

// Extract the part after '/mapping/'
const extractMappingPath = (path) => {
  const mappingIndex = path.indexOf("/mapping/");
  if (mappingIndex !== -1) {
    return path.substring(mappingIndex + "/mapping/".length);
  }
  return "";
};

const ViewMapTableDrawer = () => {
  const { companyManagement } = useLayoutContext();
  const { pathname } = useLocation();
  const tableColumns = useRef([]);
  const [tableData, setTableData] = useState([]);
  const [columnsState, setColumnsState] = useState([]);

  const query = new URLSearchParams(location.search);
  const mapTableId = query.get("map_table_id");

  const { data, isFetching: isFetchingMapTableData } = useGetListMapTableData(
    {
      folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
      company_slug: companyManagement?.company?.detail?.company?.slug || "",
      map_table_id: mapTableId,
    },
    !!mapTableId
  );

  const simulateResult = useMemo(() => {
    return data?.results?.[0];
  }, [data]);

  const mappingPath = extractMappingPath(pathname);
  const { mapTableData, isFetching } = useGetMapTableById({
    folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
    company_slug: companyManagement?.company?.detail?.company?.slug || "",
    map_table_name: mappingPath,
  });

  // Tích hợp useAppShowHideTableColumn
  const { filteredTableColumns, tableColumnDisplayConfigurator } =
    useAppShowHideTableColumn({
      tableKey: TABLE_KEY,
      tableColumns: columnsState, // Dùng columnsState làm nguồn cột
    });

  useEffect(() => {
    if (
      !simulateResult ||
      !simulateResult.fields_A ||
      !simulateResult.fields_B
    ) {
      return;
    }

    const columns = [
      {
        title: "Table Name",
        dataIndex: "table_name",
        key: "table_name",
        width: 120,
      },
      ...(simulateResult.fields_A || []).map((field) => ({
        title: field,
        dataIndex: field,
        key: field,
        width: 120,
      })),
      ...(simulateResult.fields_B || []).map((field) => ({
        title: field,
        dataIndex: field,
        key: field,
        width: 120,
      })),
      {
        title: "Mapping Date",
        dataIndex: "created_at",
        key: "created_at",
        width: 120,
      },
    ];

    // Cập nhật tableColumns (dùng useRef)
    tableColumns.current = columns;

    // Cập nhật columnsState cho AppTableContainer
    setColumnsState(
      columns.map((column, i) => ({
        ...column,
        key: `${i}`,
        onHeaderCell: () => ({
          id: `${i}`,
          draggable: true,
        }),
        onCell: () => ({
          id: `${i}`,
        }),
      }))
    );

    const commonParams = {
      folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
      company_slug: companyManagement?.company?.detail?.company?.slug || "",
    };

    const fetchData = async () => {
      const requests = [
        fetchMapTableById(mappingPath, {
          ...commonParams,
          table_name: simulateResult?.table_A_name,
        }),
        fetchMapTableById(mappingPath, {
          ...commonParams,
          table_name: simulateResult?.table_B_name,
        }),
      ];

      try {
        const [tableAResult, tableBResult] = await Promise.all(requests);

        setTableData([
          ...(tableAResult || []).map((item) => ({
            ...item,
            table_name: simulateResult?.table_A_name,
          })),
          ...(tableBResult || []).map((item) => ({
            ...item,
            table_name: simulateResult?.table_B_name,
          })),
        ]);
      } catch (error) {
        console.error("Failed to fetch map tables:", error);
      }
    };

    fetchData();
  }, [simulateResult, companyManagement, mappingPath]);

  const { messages } = useIntl();

  return (
    <div style={{ padding: "16px 20px 0", background: "#fff" }}>
      <div className="app-view-detail">
        <div className="app-view-detail-header">
          <Button type="text" icon={<LeftOutlined />} className="btn-back">
            <Link to="/mapping" style={{ color: "#000" }}>
              <IntlMessages id="common.backToList" />
            </Link>
          </Button>
        </div>

        <div>
          {isFetching || isFetchingMapTableData ? (
            <Spin
              spinning
              style={{
                ...centerItems,
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                overflow: "hidden",
                zIndex: 99999999,
                height: "100vh",
              }}
            />
          ) : (
            <div style={{ marginTop: 20 }}>
              {/* {tableColumns?.current.length > 0 && tableData.length > 0 && ( */}
              <AppTableContainer
                columns={filteredTableColumns}
                columnsState={columnsState}
                // setColumnsState={setColumnsState}
                data={tableData}
                rowKey="_id"
                loading={isFetching || isFetchingMapTableData}
              />
              {/* )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewMapTableDrawer;
