import IntlMessages from "@uni/utility/IntlMessages";
import { formatNumberBalance } from "pages/balance/Utils";

const useGetViewFormAFieldConfig = () => {
  return [
    {
      title: <IntlMessages id="determination.determine.code" />,
      dataIndex: "code",
      key: "code",
      width: 120,
    },
    {
      title: <IntlMessages id="determination.determine.itemName" />,
      dataIndex: "item_name",
      key: "item_name",
      width: 500,
    },
    {
      title: <IntlMessages id="determination.determine.hsCode" />,
      dataIndex: "hs_code",
      key: "hs_code",
      width: 120,
    },
    {
      title: <IntlMessages id="determination.determine.totalValue" />,
      dataIndex: "total_value",
      key: "total_value",
      width: 120,
      render: (value) => <div>{value ? formatNumberBalance(value) : 0}</div>,
    },
    {
      title: <IntlMessages id="determination.determine.totalQuantity" />,
      dataIndex: "total_quantity",
      key: "total_quantity",
      width: 120,
      render: (value) => <div>{value ? formatNumberBalance(value) : 0}</div>,
    },
    {
      title: <IntlMessages id="determination.determine.ntValue" />,
      dataIndex: "nt_value",
      key: "nt_value",
      width: 120,
      render: (value) => <div>{value ? formatNumberBalance(value) : 0}</div>,
    },
  ];
};

export default useGetViewFormAFieldConfig;
