import AppViewDetail from "@uni/core/AppViewDetail";
import { downloadExcelStatement15A } from "@uni/utility/BalanceExport";
import useGetStatementById from "hooks/apis/makingStatement/useGetStatement15ById";
import { formatNumberBalance } from "pages/balance/Utils";
import { forwardRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  COL_WIDTHS_MAKING_STATEMENT15A,
  FORMAT_CELLS_MAKING_STATEMENT15A,
  MERGE_CELLS_MAKING_STATEMENT15A,
} from "shared/constants/BalanceCons";

const ViewStatement15ADetail = forwardRef(({ id, setDetailId }, ref) => {
  const { statement } = useGetStatementById(id);
  const [dataTable, setDataTable] = useState({});
  const { messages } = useIntl();

  useEffect(() => {
    if (statement) {
      const convertDataToTable = statement?.data?.map((item, index) => [
        index + 1,
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.begin_quantity ? formatNumberBalance(item?.begin_quantity) : 0,
        item?.import_quantity ? formatNumberBalance(item?.import_quantity) : 0,
        item?.A42 ? formatNumberBalance(item?.A42) : 0,
        item?.export_quantity ? formatNumberBalance(item?.export_quantity) : 0,
        item?.other_export_quantity
          ? formatNumberBalance(item?.other_export_quantity)
          : 0,
        item?.end_quantity ? formatNumberBalance(item?.end_quantity) : 0,
      ]);

      const data = [
        [
          messages["data.exim.statement15.no"],
          messages["data.exim.statement15A.code"],
          messages["data.exim.statement15A.name"],
          messages["data.exim.statement15A.unit"],
          messages["data.exim.statement15A.beginQuantity"],
          messages["data.exim.statement15A.importQuantity"],
          messages["data.exim.statement15.export2"],
          "",
          "",
          messages["data.exim.statement15A.endQuantity"],
          messages["common.notes"],
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          messages["data.exim.statement15A.rePurposeQuantity"],
          messages["data.exim.statement15A.exportQuantity"],
          messages["data.exim.statement15A.otherExportQuantity"],
          "",
          "",
        ],
        ...convertDataToTable,
      ];

      setDataTable({
        data,
        mergeCells: MERGE_CELLS_MAKING_STATEMENT15A,
        formatCell: FORMAT_CELLS_MAKING_STATEMENT15A,
        colWidths: COL_WIDTHS_MAKING_STATEMENT15A,
      });
    }
  }, [statement, messages]);

  return (
    <AppViewDetail
      setDetailId={setDetailId}
      dataTable={dataTable}
      downloadExcel={
        statement?.data &&
        (() =>
          downloadExcelStatement15A(statement?.data, "STATEMENT15A", messages))
      }
    />
  );
});

export default ViewStatement15ADetail;
