import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getMapTableById = (params) =>
  jwtAxios.get(`${URL_API.MAP_TABLE.GET_MAP_TABLE_BY_ID}`, {
    params,
  });

const useGetMapTableById = (params) => {
  const { data, ...rest } = useQuery(
    [URL_API.MAP_TABLE.GET_MAP_TABLE_BY_ID, params],
    () => getMapTableById(params),
    {
      enabled: !!params.map_table_name,
    }
  );
  return { mapTableData: data, ...rest };
};

export default useGetMapTableById;
