import React, { createContext, useState, useContext } from "react";

// Tạo context
const MapTableContext = createContext();

// Tạo provider cho context
export const MapTableProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [result, setResult] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modifyData, setModifyData] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);

  const [listTableStore, setListTableStore] = useState([]);

  const [mapTableId, setMapTableId] = useState("");

  const [totalSubtracted, setTotalSubtracted] = useState(0);
  const [isMappedDataExceed, setIsMappedDataExceed] = useState(false);

  const [listKeyTableA, setListKeyTableA] = useState([]);
  const [listKeyTableB, setListKeyTableB] = useState([]);
  const [mappedTableData, setMappedTableData] = useState({
    rows_table_A: [],
    rows_table_B: [],
  });

  const nextStep = () => {
    setCurrentStep(currentStep === 2 ? 0 : currentStep + 1);
  };
  const prevStep = () => {
    setMapTableId("");
    setCurrentStep(currentStep - 1);
  };

  const revertLastMapping = () => {
    setMappedTableData((prev) => ({
      ...prev,
      rows_table_A: prev.rows_table_A.slice(0, -1),
      rows_table_B: prev.rows_table_B.slice(0, -1),
    }));
  };

  const resetMapping = () => {
    setMappedTableData(() => ({
      rows_table_A: [],
      rows_table_B: [],
    }));
  };

  const isMappedRemainData =
    mappedTableData.rows_table_A.length > 0 &&
    mappedTableData.rows_table_B.length > 0;

  return (
    <MapTableContext.Provider
      value={{
        setCurrentStep,
        currentStep,
        nextStep,
        prevStep,

        result,
        setResult,
        isLoading,
        setIsLoading,
        modifyData,
        setModifyData,
        listTableStore,
        setListTableStore,
        setIsEnabled,
        isEnabled,
        listKeyTableA,
        setListKeyTableA,
        listKeyTableB,
        setListKeyTableB,
        mappedTableData,
        setMappedTableData,
        revertLastMapping,
        resetMapping,
        isMappedRemainData,
        setMapTableId,
        mapTableId,
        totalSubtracted,
        setTotalSubtracted,
        setIsMappedDataExceed,
        isMappedDataExceed,
      }}
    >
      {children}
    </MapTableContext.Provider>
  );
};

// Hook để sử dụng context
export const useMapTableContext = () => useContext(MapTableContext);
