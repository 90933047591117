import { CheckCircleOutlined } from "@ant-design/icons";
import IntlMessages from "@uni/utility/IntlMessages";
import { formatNumberBalance } from "pages/balance/Utils";

const useGetViewDetermineFieldConfig = () => {
  return [
    {
      title: <IntlMessages id="determination.determine.isRunBangke" />,
      dataIndex: "is_run_bangke",
      key: "is_run_bangke",
      width: 100,
      render: (_, record) =>
        record?.is_run_bangke && (
          <CheckCircleOutlined style={{ color: "green", display: "block" }} />
        ),
    },
    {
      title: <IntlMessages id="determination.determine.code" />,
      dataIndex: "code",
      key: "code",
      width: 120,
    },
    {
      title: <IntlMessages id="determination.determine.itemName" />,
      dataIndex: "item_name",
      key: "item_name",
      width: 200,
    },
    {
      title: <IntlMessages id="determination.determine.hsCode" />,
      dataIndex: "hs_code",
      key: "hs_code",
      width: 120,
    },
    {
      title: <IntlMessages id="determination.determine.condition" />,
      dataIndex: "invoice_price_condition",
      key: "invoice_price_condition",
      width: 120,
    },
    {
      title: <IntlMessages id="determination.determine.totalValue" />,
      dataIndex: "total_value",
      key: "total_value",
      width: 120,
      render: (value) => <div>{value ? formatNumberBalance(value) : 0}</div>,
    },
    {
      title: <IntlMessages id="determination.determine.totalQuantity" />,
      dataIndex: "total_quantity",
      key: "total_quantity",
      width: 120,
      render: (value) => <div>{value ? formatNumberBalance(value) : 0}</div>,
    },
    {
      title: <IntlMessages id="determination.determine.ntValue" />,
      dataIndex: "nt_value",
      key: "nt_value",
      width: 120,
      render: (value) => <div>{value ? formatNumberBalance(value) : 0}</div>,
    },
    {
      title: <IntlMessages id="data.exim.ecus.importCountry" />,
      dataIndex: "import_country",
      key: "import_country",
      width: 140,
    },
  ];
};

export default useGetViewDetermineFieldConfig;
