import { MapTableProvider } from "@uni/utility/AppContextProvider/MapTableContextProvider";
import React from "react";
import ViewMapTableDrawer from "./MapTable/ViewMapTableDrawer";
const MapTable = React.lazy(() => import("./MapTable"));

export const mapTableConfig = [
  {
    path: "/mapping",
    element: (
      <MapTableProvider>
        <MapTable />
      </MapTableProvider>
    ),
  },
  {
    path: "/mapping/:name",
    element: <ViewMapTableDrawer />,
  },
];
