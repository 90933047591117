import { formatNumberBalance } from "pages/balance/Utils";
import {
  COL_EXCEL_WIDTHS_BALANCE,
  COL_EXCEL_WIDTHS_BCQT_STATEMENT15,
  COL_EXCEL_WIDTHS_BEGIN_STATEMENT15,
  COL_EXCEL_WIDTHS_INVENTORY_STATEMENT15,
  COL_EXCEL_WIDTHS_MAKING_STATEMENT15,
  COL_EXCEL_WIDTHS_MAKING_STATEMENT15A,
  COL_EXCEL_WIDTHS_TAKE_IN_STATEMENT15,
  COL_EXCEL_WIDTHS_TAKE_OUT_STATEMENT15,
  COL_WIDTHS_MISSING,
  COL_WIDTHS_TAKE_OUT_STATEMENT15A,
  MERGE_CELLS_BALANCE,
  MERGE_CELLS_BCQT_STATEMENT15,
  MERGE_CELLS_BCQT_STATEMENT15A,
  MERGE_CELLS_BEGIN_STATEMENT15,
  MERGE_CELLS_INVENTORY_STATEMENT15,
  MERGE_CELLS_MAKING_STATEMENT15,
  MERGE_CELLS_MAKING_STATEMENT15A,
  MERGE_CELLS_MISSING,
  MERGE_CELLS_TAKE_IN_STATEMENT15,
  MERGE_CELLS_TAKE_IN_STATEMENT15A,
  MERGE_CELLS_TAKE_OUT_STATEMENT15,
  MERGE_CELLS_TAKE_OUT_STATEMENT15A,
} from "shared/constants/BalanceCons";
import * as XLSX from "xlsx-js-style";

const commonFormatCell = {
  font: {
    bold: true,
  },
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
  border: {
    top: { style: "thin", color: { rgb: "000000" } }, // Top border
    bottom: { style: "thin", color: { rgb: "000000" } }, // Bottom border
    left: { style: "thin", color: { rgb: "000000" } }, // Left border
    right: { style: "thin", color: { rgb: "000000" } }, // Right border
  },
};

const applyBackgroundWS = (ws, sheetData, bg) => {
  for (let i = 0; i < sheetData?.[bg]?.cells.length; i++) {
    ws[sheetData?.[bg]?.cells[i]].s = {
      fill: {
        fgColor: { rgb: sheetData?.[bg]?.bgColor },
      },
      ...commonFormatCell,
    };
  }
};

export const downloadExcelStatement15 = (dataExcel, fileName, messages) => {
  const workbook = XLSX.utils.book_new();

  const convertDataToTable = dataExcel?.map((item, index) => [
    index + 1,
    item?.code || "",
    item?.item_name || "",
    item?.unit || "",
    item?.begin_quantity ? formatNumberBalance(item?.begin_quantity) : 0,
    item?.import_quantity ? formatNumberBalance(item?.import_quantity) : 0,
    item?.B13 ? formatNumberBalance(item?.B13) : 0,
    item?.A42 ? formatNumberBalance(item?.A42) : 0,
    item?.export_quantity ? formatNumberBalance(item?.export_quantity) : 0,
    item?.other_export_quantity
      ? formatNumberBalance(item?.other_export_quantity)
      : 0,
    item?.end_quantity ? formatNumberBalance(item?.end_quantity) : 0,
    "",
  ]);

  const dataHeader = [
    [
      messages["data.exim.statement15.no"],
      messages["data.exim.statement15.code"],
      messages["data.exim.statement15.name"],
      messages["data.exim.statement15.unit"],
      messages["data.exim.statement15.beginQuantity"],
      messages["data.exim.statement15.importQuantity"],
      messages["data.exim.statement15.export1"],
      "",
      "",
      "",
      messages["data.exim.statement15.endQuantity"],
      messages["common.notes"],
    ],
    [
      "",
      "",
      "",
      "",
      "",
      "",
      messages["data.exim.statement15.reExportQuantity"],
      messages["data.exim.statement15.rePurposeQuantity"],
      messages["data.exim.statement15.exportToProduceQuantity"],
      messages["data.exim.statement15.otherExportQuantity"],
      "",
      "",
    ],
  ].map((item) =>
    item.map((itemChild) => ({
      v: itemChild,
      t: "s",
      s: {
        ...commonFormatCell,
      },
    }))
  );

  const worksheet = XLSX.utils.aoa_to_sheet([
    ...dataHeader,
    ...convertDataToTable.map((item) =>
      item.map((itemChild) => ({
        v: itemChild,
        t: "s",
        s: {
          border: { ...commonFormatCell.border },
        },
      }))
    ),
  ]);

  worksheet["!merges"] = MERGE_CELLS_MAKING_STATEMENT15.map((merge) => {
    return {
      s: { r: merge.row, c: merge.col },
      e: {
        r: merge.row + merge.rowspan - 1,
        c: merge.col + merge.colspan - 1,
      },
    };
  });

  worksheet["!cols"] = COL_EXCEL_WIDTHS_MAKING_STATEMENT15.map((width) => ({
    width,
  }));

  const sheetName = fileName;
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, `${fileName}Export.xlsx`);
};

export const downloadExcelStatement15A = (dataExcel, fileName, messages) => {
  const workbook = XLSX.utils.book_new();

  const convertDataToTable = dataExcel?.map((item, index) => [
    index + 1,
    item?.code || "",
    item?.item_name || "",
    item?.unit || "",
    item?.begin_quantity ? formatNumberBalance(item?.begin_quantity) : 0,
    item?.import_quantity ? formatNumberBalance(item?.import_quantity) : 0,
    item?.A42 ? formatNumberBalance(item?.A42) : 0,
    item?.export_quantity ? formatNumberBalance(item?.export_quantity) : 0,
    item?.other_export_quantity
      ? formatNumberBalance(item?.other_export_quantity)
      : 0,
    item?.end_quantity ? formatNumberBalance(item?.end_quantity) : 0,
    "",
  ]);

  const dataHeader = [
    [
      messages["data.exim.statement15.no"],
      messages["data.exim.statement15A.code"],
      messages["data.exim.statement15A.name"],
      messages["data.exim.statement15A.unit"],
      messages["data.exim.statement15A.beginQuantity"],
      messages["data.exim.statement15A.importQuantity"],
      messages["data.exim.statement15.export2"],
      "",
      "",
      messages["data.exim.statement15A.endQuantity"],
      messages["common.notes"],
    ],
    [
      "",
      "",
      "",
      "",
      "",
      "",
      messages["data.exim.statement15A.rePurposeQuantity"],
      messages["data.exim.statement15A.exportQuantity"],
      messages["data.exim.statement15A.otherExportQuantity"],
      "",
      "",
    ],
  ].map((item) =>
    item.map((itemChild) => ({
      v: itemChild,
      t: "s",
      s: {
        ...commonFormatCell,
      },
    }))
  );

  const worksheet = XLSX.utils.aoa_to_sheet([
    ...dataHeader,
    ...convertDataToTable.map((item) =>
      item.map((itemChild) => ({
        v: itemChild,
        t: "s",
        s: {
          border: { ...commonFormatCell.border },
        },
      }))
    ),
  ]);

  worksheet["!merges"] = MERGE_CELLS_MAKING_STATEMENT15A.map((merge) => {
    return {
      s: { r: merge.row, c: merge.col },
      e: {
        r: merge.row + merge.rowspan - 1,
        c: merge.col + merge.colspan - 1,
      },
    };
  });

  worksheet["!cols"] = COL_EXCEL_WIDTHS_MAKING_STATEMENT15A.map((width) => ({
    width,
  }));

  const sheetName = fileName;
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, `${fileName}Export.xlsx`);
};

export const downloadExcelBalance = (dataExcel, fileName, messages) => {
  const workbook = XLSX.utils.book_new();

  const convertDataToTable = dataExcel?.map((item, index) => [
    item?.material_code || "",
    item?.item_name || "",
    item?.unit_ecus || "",
    item?.unit || "",
    item?.begin_quantity_15 ? formatNumberBalance(item?.begin_quantity_15) : 0,
    item?.begin_quantity_15A
      ? formatNumberBalance(item?.begin_quantity_15A)
      : 0,
    item?.import_quantity_15
      ? formatNumberBalance(item?.import_quantity_15)
      : 0,

    item?.re_export_quantity_15
      ? formatNumberBalance(item?.re_export_quantity_15)
      : 0,
    item?.re_purpose_quantity_15
      ? formatNumberBalance(item?.re_purpose_quantity_15)
      : 0,
    item?.re_purpose_quantity_15A
      ? formatNumberBalance(item?.re_purpose_quantity_15A)
      : 0,

    item?.other_export_quantity_15
      ? formatNumberBalance(item?.other_export_quantity_15)
      : 0,
    item?.other_export_quantity_15A
      ? formatNumberBalance(item?.other_export_quantity_15A)
      : 0,

    item?.export_quantity ? formatNumberBalance(item?.export_quantity) : 0,

    item?.ECUS ? formatNumberBalance(item?.ECUS) : 0,
    item?.end_quantity_15 ? formatNumberBalance(item?.end_quantity_15) : 0,
    item?.end_quantity_15A ? formatNumberBalance(item?.end_quantity_15A) : 0,
    item?.BALANCE ? formatNumberBalance(item?.BALANCE) : 0,
    item?.IMPOSED_QUANTITY ? formatNumberBalance(item?.IMPOSED_QUANTITY) : 0,

    item?.import_tax ? formatNumberBalance(item?.import_tax) : 0,
    item?.vat_tax ? formatNumberBalance(item?.vat_tax) : 0,

    item?.IMPORT_TAX_AMT ? formatNumberBalance(item?.IMPORT_TAX_AMT) : 0,
    item?.VAT_AMT ? formatNumberBalance(item?.VAT_AMT) : 0,
    item?.PENALTY ? formatNumberBalance(item?.PENALTY) : 0,
    item?.TOTAL_TAX_AMT ? formatNumberBalance(item?.TOTAL_TAX_AMT) : 0,
  ]);

  const dataHeader = [
    [
      "ITEM CODE",
      "ITEM NAME",
      "UNIT",
      "",
      "BEGIN",
      "",
      "IMPORT",
      "Re-export",
      "Re-purpose",
      "",
      "Other out put",
      "",
      "EXPORT",
      "ECUS",
      "ENDING",
      "",
      "BALANCE",
      "IMPOSE QUANTITY",
      "IMPORT TAX AVERAGE",
      "VAT AVERAGE",
      "IMPORT TAX AMT",
      "VAT AMT",
      "PENALTY",
      "TOTAL TAX AMT",
    ],
    [
      "",
      "",
      "ECUS",
      "STATEMENT",
      "RM",
      "FG",
      "",
      "",
      "RM",
      "FG",
      "RM",
      "FG",
      "",
      "",
      "RM",
      "FG",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
  ].map((item) =>
    item.map((itemChild) => ({
      v: itemChild,
      t: "s",
      s: {
        ...commonFormatCell,
      },
    }))
  );

  const worksheet = XLSX.utils.aoa_to_sheet([
    ...dataHeader,
    ...convertDataToTable.map((item) =>
      item.map((itemChild) => ({
        v: itemChild,
        t: "s",
        s: {
          border: { ...commonFormatCell.border },
        },
      }))
    ),
  ]);

  worksheet["!merges"] = MERGE_CELLS_BALANCE.map((merge) => {
    return {
      s: { r: merge.row, c: merge.col },
      e: {
        r: merge.row + merge.rowspan - 1,
        c: merge.col + merge.colspan - 1,
      },
    };
  });

  worksheet["!cols"] = COL_EXCEL_WIDTHS_BALANCE.map((width) => ({
    width,
  }));

  const sheetName = fileName;
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, `${fileName}Export.xlsx`);
};

export const downloadExcelCheckingStatement15 = (
  dataExcel,
  fileName,
  messages
) => {
  const workbook = XLSX.utils.book_new();

  const dataListWorkSheet = [
    {
      name: "BCQT",
      dataHeader: [
        [
          "NO",
          "CODE",
          "NAME",
          "UNIT",
          "BEGIN",
          "TAKE IN",
          "TAKE OUT",
          "",
          "",
          "",
          "ENDING INVENTORY",
          "NOTE",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "RE-EXPORT",
          "RE-PURPOSE",
          "OUTPUT PRODUCTION",
          "OTHER OUTPUT",
          "",
          "",
        ],
        [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "(11)=(5)+(6)-(7)-(8)-(9)-(10)",
          "12",
        ],
      ],
      convertDataToTable: dataExcel?.current_statement_sheet?.map(
        (item, index) => [
          index + 1,
          item?.code || "",
          item?.item_name || "",
          item?.unit || "",
          item?.begin_quantity ? formatNumberBalance(item?.begin_quantity) : 0,
          item?.import_quantity
            ? formatNumberBalance(item?.import_quantity)
            : 0,
          item?.re_export_quantity
            ? formatNumberBalance(item?.re_export_quantity)
            : 0,
          item?.re_purpose_quantity
            ? formatNumberBalance(item?.re_purpose_quantity)
            : 0,
          item?.export_to_produce_quantity
            ? formatNumberBalance(item?.export_to_produce_quantity)
            : 0,
          item?.other_export_quantity_statement
            ? formatNumberBalance(item?.other_export_quantity_statement)
            : 0,
          item?.end_quantity ? formatNumberBalance(item?.end_quantity) : 0,
          "",
        ]
      ),
      mergeCell: MERGE_CELLS_BCQT_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_BCQT_STATEMENT15,
      bgOrange: {
        cells: ["E1", "F1", "G1", "K1", "G2", "H2", "I2", "J2"],
        bgColor: "ffc000",
      },
    },
    {
      name: "BEGIN",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "BEGIN STATEMENT",
          "ENDING INVENTORY LAST STATEMENT",
          "BEGIN ACC",
          "BEGIN WH",
          "GAP",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "STATEMENT - LAST STATEMENT",
          "ACC - STATEMENT ",
          "WH - STATEMENT",
        ],
      ],
      convertDataToTable: dataExcel?.begin_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.begin_quantity ? formatNumberBalance(item?.begin_quantity) : 0,
        item?.end_quantity ? formatNumberBalance(item?.end_quantity) : 0,
        item?.begin_quantity_acc
          ? formatNumberBalance(item?.begin_quantity_acc)
          : 0,
        item?.begin_quantity_wh
          ? formatNumberBalance(item?.begin_quantity_wh)
          : 0,
        item?.gap_bcqt ? formatNumberBalance(item?.gap_bcqt) : 0,
        item?.gap_bcqt_acc ? formatNumberBalance(item?.gap_bcqt_acc) : 0,
        item?.gap_bcqt_wh ? formatNumberBalance(item?.gap_bcqt_wh) : 0,
      ]),
      mergeCell: MERGE_CELLS_BEGIN_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_BEGIN_STATEMENT15,
      bgOrange: {
        cells: ["D1"],
        bgColor: "ffc000",
      },
      bgBlue: {
        cells: ["E1", "F1", "G1"],
        bgColor: "83cceb",
      },
    },
    {
      name: "TAKE IN",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "TAKE IN STATEMENT ",
          "E21/E31/(E11+E15)",
          "TAKE IN ACC",
          "TAKE IN WH",
          "TAKE IN PURCHASE",
          "GAP",
          "",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "E21/E31/(E11+E15) - STATEMENT ",
          "ACC - STATEMENT ",
          "WH - STATEMENT",
          "PURCHASE - STATEMENT ",
        ],
      ],
      convertDataToTable: dataExcel?.take_in_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.import_quantity ? formatNumberBalance(item?.import_quantity) : 0,
        item?.E21 ? formatNumberBalance(item?.E21) : 0,
        item?.import_quantity_acc
          ? formatNumberBalance(item?.import_quantity_acc)
          : 0,
        item?.import_quantity_wh
          ? formatNumberBalance(item?.import_quantity_wh)
          : 0,
        item?.total_quantity ? formatNumberBalance(item?.total_quantity) : 0,
        item?.gap_E21_E31_E11_E15
          ? formatNumberBalance(item?.gap_E21_E31_E11_E15)
          : 0,
        item?.gap_import_quantity_acc
          ? formatNumberBalance(item?.gap_import_quantity_acc)
          : 0,
        item?.gap_import_quantity_wh
          ? formatNumberBalance(item?.gap_import_quantity_wh)
          : 0,
        item?.gap_total_quantity
          ? formatNumberBalance(item?.gap_total_quantity)
          : 0,
      ]),
      mergeCell: MERGE_CELLS_TAKE_IN_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_TAKE_IN_STATEMENT15,
      bgOrange: {
        cells: ["D1"],
        bgColor: "ffc000",
      },
      bgBlue: {
        cells: ["E1", "F1", "G1", "H1"],
        bgColor: "83cceb",
      },
    },
    {
      name: "TAKE OUT",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "TAKE OUT STATEMENT ",
          "",
          "",
          "",
          "B13",
          "A42",
          "TAKE OUT ACC",
          "",
          "TAKE OUT WH",
          "GAP",
          "",
          "",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "Re-Export",
          "Re-Purpose",
          "Output Production",
          "Other Output",
          "",
          "",
          "Out put production",
          "Other output",
          "Out put production",
          "Re-Export",
          "Re-purpose",
          "Out put production",
          "",
          "Other output",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "B13 - STATEMENT ",
          "A42 - STATEMENT ",
          "ACC - STATEMENT ",
          "WH - STATEMENT ",
          "ACC - STATEMENT ",
        ],
      ],
      convertDataToTable: dataExcel?.take_out_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.re_export_quantity
          ? formatNumberBalance(item?.re_export_quantity)
          : 0,
        item?.re_purpose_quantity
          ? formatNumberBalance(item?.re_purpose_quantity)
          : 0,
        item?.export_to_produce_quantity
          ? formatNumberBalance(item?.export_to_produce_quantity)
          : 0,
        item?.other_export_quantity_statement
          ? formatNumberBalance(item?.other_export_quantity_statement)
          : 0,
        item?.B13 ? formatNumberBalance(item?.B13) : 0,
        item?.A42 ? formatNumberBalance(item?.A42) : 0,
        item?.export_quantity_acc
          ? formatNumberBalance(item?.export_quantity_acc)
          : 0,
        item?.other_export_quantity_remain
          ? formatNumberBalance(item?.other_export_quantity_remain)
          : 0,
        item?.export_quantity_wh
          ? formatNumberBalance(item?.export_quantity_wh)
          : 0,
        item?.gap_re_export ? formatNumberBalance(item?.gap_re_export) : 0,
        item?.gap_re_purpose ? formatNumberBalance(item?.gap_re_purpose) : 0,
        item?.gap_export_acc ? formatNumberBalance(item?.gap_export_acc) : 0,
        item?.gap_export_wh ? formatNumberBalance(item?.gap_export_wh) : 0,
        item?.gap_other_export
          ? formatNumberBalance(item?.gap_other_export)
          : 0,
      ]),
      mergeCell: MERGE_CELLS_TAKE_OUT_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_TAKE_OUT_STATEMENT15,
      bgOrange: {
        cells: ["D1", "D2", "E2", "F2", "G2"],
        bgColor: "ffc000",
      },
      bgBlue: {
        cells: ["H1", "I1", "J1", "L1", "J2", "K2", "L2"],
        bgColor: "83cceb",
      },
    },
    {
      name: "INVENTORY",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "ENDING INVENTORY STATEMENT ",
          "ENDING INVENTORY ACC",
          "ENDING INVENTORY WH",
          "PHYSICAL INVENTORY ACC",
          "PHYSICAL INVENTORY WH",
          "GAP",
          "",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "ACC - STATEMENT",
          "WH - STATEMENT",
          "PHYSICAL INVENTORY ACC - STATEMENT",
          "PHYSICAL INVENTORY WH - STATEMENT",
        ],
      ],
      convertDataToTable: dataExcel?.inventory_sheet?.map((item, index) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.end_quantity ? formatNumberBalance(item?.end_quantity) : 0,
        item?.end_quantity_acc
          ? formatNumberBalance(item?.end_quantity_acc)
          : 0,
        item?.end_quantity_wh ? formatNumberBalance(item?.end_quantity_wh) : 0,
        item?.end_quantity_ph_acc
          ? formatNumberBalance(item?.end_quantity_ph_acc)
          : 0,
        item?.end_quantity_ph_wh
          ? formatNumberBalance(item?.end_quantity_ph_wh)
          : 0,
        item?.gap_end_acc ? formatNumberBalance(item?.gap_end_acc) : 0,
        item?.gap_end_wh ? formatNumberBalance(item?.gap_end_wh) : 0,
        item?.gap_end_ph_acc ? formatNumberBalance(item?.gap_end_ph_acc) : 0,
        item?.gap_end_ph_wh ? formatNumberBalance(item?.gap_end_ph_wh) : 0,
      ]),
      mergeCell: MERGE_CELLS_INVENTORY_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_INVENTORY_STATEMENT15,
      bgOrange: {
        cells: ["D1"],
        bgColor: "ffc000",
      },
      bgBlue: {
        cells: ["E1", "F1", "G1", "H1"],
        bgColor: "83cceb",
      },
    },
    {
      name: "MISSING CODE",
      dataHeader: [["CODE", "UNIT"]],
      convertDataToTable:
        dataExcel?.missing_code_sheet?.map((item, index) => [
          item?.code || "",
          item?.unit || "",
        ]) || [],
      mergeCell: MERGE_CELLS_MISSING,
      colWidth: COL_WIDTHS_MISSING,
    },
    {
      name: "MISSING UNIT",
      dataHeader: [["CODE", "UNIT"]],
      convertDataToTable:
        dataExcel?.missing_unit_sheet?.map((item, index) => [
          item?.code || "",
          item?.unit || "",
        ]) || [],
      mergeCell: MERGE_CELLS_MISSING,
      colWidth: COL_WIDTHS_MISSING,
    },
  ];

  // iterable to create & add sheet to excel
  dataListWorkSheet.forEach((sheetData) => {
    const worksheet = XLSX.utils.aoa_to_sheet([
      ...sheetData.dataHeader.map((item) =>
        item.map((itemChild) => ({
          v: itemChild,
          t: "s",
          s: {
            ...commonFormatCell,
          },
        }))
      ),
      ...sheetData.convertDataToTable.map((item) =>
        item.map((itemChild) => ({
          v: itemChild,
          t: "s",
          s: {
            border: { ...commonFormatCell.border },
          },
        }))
      ),
    ]);

    worksheet["!merges"] = sheetData.mergeCell.map((merge) => {
      return {
        s: { r: merge.row, c: merge.col },
        e: {
          r: merge.row + merge.rowspan - 1,
          c: merge.col + merge.colspan - 1,
        },
      };
    });

    worksheet["!cols"] = sheetData.colWidth.map((width) => ({
      width,
    }));

    // Apply background color
    if (sheetData?.bgOrange || sheetData?.bgBlue) {
      if (sheetData?.bgOrange) {
        applyBackgroundWS(worksheet, sheetData, "bgOrange");
      }
      if (sheetData?.bgBlue) {
        applyBackgroundWS(worksheet, sheetData, "bgBlue");
      }
    }

    const sheetName = sheetData.name;
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  XLSX.writeFile(workbook, `${fileName}Export.xlsx`);
};

export const downloadExcelCheckingStatement15A = (
  dataExcel,
  fileName,
  messages
) => {
  const workbook = XLSX.utils.book_new();

  const dataListWorkSheet = [
    {
      name: "BCQT",
      dataHeader: [
        [
          "NO",
          "CODE",
          "NAME",
          "UNIT",
          "BEGIN",
          "TAKE IN",
          "TAKE OUT",
          "",
          "",
          "ENDING INVENTORY",
          "NOTE",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "RE-PURPOSE",
          "EXPORT",
          "OTHER OUTPUT",
          "",
          "",
        ],
        [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "(10)=(5)+(6)-(7)-(8)-(9)",
          "11",
        ],
      ],
      convertDataToTable: dataExcel?.current_statement_sheet?.map(
        (item, index) => [
          index + 1,
          item?.code || "",
          item?.item_name || "",
          item?.unit || "",
          item?.begin_quantity ? formatNumberBalance(item?.begin_quantity) : 0,
          item?.import_quantity
            ? formatNumberBalance(item?.import_quantity)
            : 0,
          item?.re_purpose_quantity
            ? formatNumberBalance(item?.re_purpose_quantity)
            : 0,
          item?.export_quantity
            ? formatNumberBalance(item?.export_quantity)
            : 0,
          item?.other_export_quantity_statement
            ? formatNumberBalance(item?.other_export_quantity_statement)
            : 0,
          item?.end_quantity ? formatNumberBalance(item?.end_quantity) : 0,
          "",
        ]
      ),
      mergeCell: MERGE_CELLS_BCQT_STATEMENT15A,
      colWidth: COL_EXCEL_WIDTHS_BCQT_STATEMENT15,
      bgYellow: {
        cells: ["E1", "F1", "G1", "J1", "G2", "H2", "I2"],
        bgColor: "ffff00",
      },
    },
    {
      name: "BEGIN",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "BEGIN STATEMENT",
          "ENDING INVENTORY LAST STATEMENT",
          "BEGIN ACC",
          "BEGIN WH",
          "GAP",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "STATEMENT - LAST STATEMENT",
          "ACC - STATEMENT ",
          "WH - STATEMENT",
        ],
      ],
      convertDataToTable: dataExcel?.begin_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.begin_quantity ? formatNumberBalance(item?.begin_quantity) : 0,
        item?.end_quantity ? formatNumberBalance(item?.end_quantity) : 0,
        item?.begin_quantity_acc
          ? formatNumberBalance(item?.begin_quantity_acc)
          : 0,
        item?.begin_quantity_wh
          ? formatNumberBalance(item?.begin_quantity_wh)
          : 0,
        item?.gap_bcqt ? formatNumberBalance(item?.gap_bcqt) : 0,
        item?.gap_bcqt_acc ? formatNumberBalance(item?.gap_bcqt_acc) : 0,
        item?.gap_bcqt_wh ? formatNumberBalance(item?.gap_bcqt_wh) : 0,
      ]),
      mergeCell: MERGE_CELLS_BEGIN_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_BEGIN_STATEMENT15,
      bgYellow: {
        cells: ["D1"],
        bgColor: "ffff00",
      },
      bgGreen: {
        cells: ["E1", "F1", "G1"],
        bgColor: "daf2d0",
      },
    },
    {
      name: "TAKE IN",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "TAKE IN STATEMENT ",
          "TAKE IN ACC",
          "TAKE IN WH",
          "GAP",
          "",
        ],
        ["", "", "", "", "", "", "ACC - STATEMENT ", "WH - STATEMENT"],
      ],
      convertDataToTable: dataExcel?.take_in_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.import_quantity ? formatNumberBalance(item?.import_quantity) : 0,
        item?.import_quantity_acc
          ? formatNumberBalance(item?.import_quantity_acc)
          : 0,
        item?.import_quantity_wh
          ? formatNumberBalance(item?.import_quantity_wh)
          : 0,
        item?.gap_total_quantity
          ? formatNumberBalance(item?.gap_total_quantity)
          : 0,
        item?.gap_import_quantity_wh
          ? formatNumberBalance(item?.gap_import_quantity_wh)
          : 0,
      ]),
      mergeCell: MERGE_CELLS_TAKE_IN_STATEMENT15A,
      colWidth: COL_EXCEL_WIDTHS_TAKE_IN_STATEMENT15,
      bgYellow: {
        cells: ["D1"],
        bgColor: "ffff00",
      },
      bgGreen: {
        cells: ["E1", "F1"],
        bgColor: "daf2d0",
      },
    },
    {
      name: "TAKE OUT",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "TAKE OUT STATEMENT ",
          "",
          "",
          "E52/E62/E42",
          "TAKE OUT ACC",
          "",
          "TAKE OUT WH",
          "TAKE OUT SALE",
          "A42",
          "GAP",
          "",
          "",
          "",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "Export",
          "Re-purpose",
          "Other output",
          "",
          "Export",
          "Other output",
          "Export",
          "Export",
          "",
          "Export",
          "",
          "",
          "",
          "Re-purpose",
          "Other output",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "ECUS - STATEMENT ",
          "ACC - STATEMENT ",
          "WH - STATEMENT ",
          "SALE - STATEMENT ",
          "A42 - STATEMENT ",
          "ACC - STATEMENT ",
        ],
      ],
      convertDataToTable: dataExcel?.take_out_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.export_quantity ? formatNumberBalance(item?.export_quantity) : 0,
        item?.re_purpose_quantity
          ? formatNumberBalance(item?.re_purpose_quantity)
          : 0,
        item?.other_export_quantity_statement
          ? formatNumberBalance(item?.other_export_quantity_statement)
          : 0,
        item?.E52_E42_E62 ? formatNumberBalance(item?.E52_E42_E62) : 0,
        item?.export_quantity_acc
          ? formatNumberBalance(item?.export_quantity_acc)
          : 0,
        item?.other_export_quantity_remain
          ? formatNumberBalance(item?.other_export_quantity_remain)
          : 0,
        item?.export_quantity_wh
          ? formatNumberBalance(item?.export_quantity_wh)
          : 0,
        item?.export_quantity_sell
          ? formatNumberBalance(item?.export_quantity_sell)
          : 0,
        item?.A42 ? formatNumberBalance(item?.A42) : 0,
        item?.gap_export_ecus ? formatNumberBalance(item?.gap_export_ecus) : 0,
        item?.gap_export_acc ? formatNumberBalance(item?.gap_export_acc) : 0,
        item?.gap_export_wh ? formatNumberBalance(item?.gap_export_wh) : 0,
        item?.gap_export_sell ? formatNumberBalance(item?.gap_export_sell) : 0,
        item?.gap_re_purpose ? formatNumberBalance(item?.gap_re_purpose) : 0,
        item?.gap_other_export
          ? formatNumberBalance(item?.gap_other_export)
          : 0,
      ]),
      mergeCell: MERGE_CELLS_TAKE_OUT_STATEMENT15A,
      colWidth: COL_EXCEL_WIDTHS_TAKE_OUT_STATEMENT15,
      bgYellow: {
        cells: ["D1", "D2", "E2", "F2"],
        bgColor: "ffff00",
      },
      bgGreen: {
        cells: ["G1", "H1", "J1", "K1", "L1", "H2", "I2", "J2", "K2"],
        bgColor: "daf2d0",
      },
    },
    {
      name: "INVENTORY",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "ENDING INVENTORY STATEMENT ",
          "ENDING INVENTORY ACC",
          "ENDING INVENTORY WH",
          "PHYSICAL INVENTORY ACC",
          "PHYSICAL INVENTORY WH",
          "GAP",
          "",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "ACC - STATEMENT",
          "WH - STATEMENT",
          "PHYSICAL INVENTORY ACC - STATEMENT",
          "PHYSICAL INVENTORY WH - STATEMENT",
        ],
      ],
      convertDataToTable: dataExcel?.inventory_sheet?.map((item, index) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.end_quantity ? formatNumberBalance(item?.end_quantity) : 0,
        item?.end_quantity_acc
          ? formatNumberBalance(item?.end_quantity_acc)
          : 0,
        item?.end_quantity_wh ? formatNumberBalance(item?.end_quantity_wh) : 0,
        item?.end_quantity_ph_acc
          ? formatNumberBalance(item?.end_quantity_ph_acc)
          : 0,
        item?.end_quantity_ph_wh
          ? formatNumberBalance(item?.end_quantity_ph_wh)
          : 0,
        item?.gap_end_acc ? formatNumberBalance(item?.gap_end_acc) : 0,
        item?.gap_end_wh ? formatNumberBalance(item?.gap_end_wh) : 0,
        item?.gap_end_ph_acc ? formatNumberBalance(item?.gap_end_ph_acc) : 0,
        item?.gap_end_ph_wh ? formatNumberBalance(item?.gap_end_ph_wh) : 0,
      ]),
      mergeCell: MERGE_CELLS_INVENTORY_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_INVENTORY_STATEMENT15,
      bgYellow: {
        cells: ["D1"],
        bgColor: "ffff00",
      },
      bgGreen: {
        cells: ["E1", "F1", "G1", "H1"],
        bgColor: "daf2d0",
      },
    },
    {
      name: "MISSING CODE",
      dataHeader: [["CODE", "UNIT"]],
      convertDataToTable:
        dataExcel?.missing_code_sheet?.map((item, index) => [
          item?.code || "",
          item?.unit || "",
        ]) || [],
      mergeCell: MERGE_CELLS_MISSING,
      colWidth: COL_WIDTHS_MISSING,
    },
    {
      name: "MISSING UNIT",
      dataHeader: [["CODE", "UNIT"]],
      convertDataToTable:
        dataExcel?.missing_unit_sheet?.map((item, index) => [
          item?.code || "",
          item?.unit || "",
        ]) || [],
      mergeCell: MERGE_CELLS_MISSING,
      colWidth: COL_WIDTHS_MISSING,
    },
  ];

  // iterable to create & add sheet to excel
  dataListWorkSheet.forEach((sheetData) => {
    const worksheet = XLSX.utils.aoa_to_sheet([
      ...sheetData.dataHeader.map((item) =>
        item.map((itemChild) => ({
          v: itemChild,
          t: "s",
          s: {
            ...commonFormatCell,
          },
        }))
      ),
      ...sheetData.convertDataToTable.map((item) =>
        item.map((itemChild) => ({
          v: itemChild,
          t: "s",
          s: {
            border: { ...commonFormatCell.border },
          },
        }))
      ),
    ]);

    worksheet["!merges"] = sheetData.mergeCell.map((merge) => {
      return {
        s: { r: merge.row, c: merge.col },
        e: {
          r: merge.row + merge.rowspan - 1,
          c: merge.col + merge.colspan - 1,
        },
      };
    });

    worksheet["!cols"] = sheetData.colWidth.map((width) => ({
      width,
    }));

    // Apply background color
    if (sheetData?.bgYellow || sheetData?.bgGreen) {
      if (sheetData?.bgYellow) {
        applyBackgroundWS(worksheet, sheetData, "bgYellow");
      }
      if (sheetData?.bgGreen) {
        applyBackgroundWS(worksheet, sheetData, "bgGreen");
      }
    }

    const sheetName = sheetData.name;
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  XLSX.writeFile(workbook, `${fileName}Export.xlsx`);
};
